import * as React from "react";

import { graphql } from "gatsby";

import { sbEditable } from "@storyblok/storyblok-editable";
import useStoryblok from "../lib/storyblok";

import Layout from "../components/Layout/Layout";
import Seo from "../components/seo";
import DynamicComponent from "../components/dynamicComponent/DynamicComponent";
import Section from "../components/section/Section";

const CaseStudies = ({ data, location }) => {

  let story = data.storyblokEntry;
  story = useStoryblok(story, location);

  const components = story.content.body.map(blok => {
      return (<DynamicComponent blok={blok} key={blok._uid} attachedBlokData={blok.component === "Section" ? {data: data.allStoryblokEntry.edges, component: "ArticleCardGroup"} : null} />)
  });
  return (
    <Layout>
      <div {...sbEditable(story.content)}>
        <Seo title="Case Studies" />
        {components}
      </div>
    </Layout>
  );

};

export default CaseStudies;

export const query = graphql`
  query CaseStudiesQuery {
    storyblokEntry(full_slug: {eq: "case-studies/"}) {
      content
      name
    }
    allStoryblokEntry(filter: {field_component: {eq: "post"}}) {
      edges {
        node {
          slug
          content
          name
          first_published_at(formatString: "DD/MM/YYYY") 
        }
      }
    }
  }
`;